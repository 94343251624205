import { Trans, useTranslation } from 'react-i18next';

import { PasswordField } from '~/components/form';

export const Password = ({ className }: Readonly<{ className?: string }>) => {
  const { t } = useTranslation('field');
  return (
    <PasswordField
      className={className}
      name='password'
      label={
        <Trans ns='field' i18nKey='password.default' t={t}>
          Password
        </Trans>
      }
      autoComplete='current-password'
    />
  );
};
